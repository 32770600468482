import { Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import PageLayout from '../components/layout';
import ReactClipboard from 'react-clipboardjs-copy';
import Slugify from 'slugify';

export default function TitleToUrl() {

    const [title, setTitle] = useState('');
    const [convertedUrl, setConvertedUrl] = useState('');
    const [isCopied, setisCopied] = useState(false);

    const handleChange = (e) => {

        let val = e.target.value;

        //replace special character
        let stage1Ur2 = Slugify(val, {
            lower: true,
            strict: true,
            trim: true,
            replacement: '-'
        })
        setConvertedUrl(stage1Ur2);
    }

    const handleCopy = () => {

    }

    return (
        <>
            <PageLayout>
                <div className="h-screen">
                    <div className="container px-5 mx-auto grid grid-cols-12" >
                        <div className="col-span-12 lg:col-span-3">

                        </div>
                        <div className="col-span-12 lg:col-span-6">
                            <div className=" ">
                                <TextField fullWidth autoFocus onChange={(event) => {
                                    handleChange(event)
                                    setisCopied(false)
                                }} label="Enter your title" variant="outlined" color="primary"></TextField>
                            </div>
                            <div className="mt-3">
                                <p className="font-bold text-2xl">
                                    Your converted URL is: </p>
                                <div className={`${convertedUrl.length ? '' : 'hidden'}`}>
                                    <p className="converted-title-to-url mt-5 mb-2 text-4xl font-semibold text-purple-600 border-2 border-purple-600 p-3 rounded-lg">
                                        {
                                            convertedUrl
                                        }
                                    </p>
                                    <ReactClipboard
                                        target={'.converted-title-to-url'}
                                        selection={true}
                                        onSuccess={(e) => setisCopied(true)}
                                        onError={(e) => setisCopied(false)}
                                    >

                                        <div title="Click to copy url" onClick={() => handleCopy()} variant="outlined" color="primary" className="inline-flex items-center align-center rounded mx-auto text-center text-white font-bold text-2xl bg-primary p-2 px-5 cursor-pointer" >Copy</div>

                                    </ReactClipboard>
                                    <span className={`${isCopied ? '' : 'hidden'} pl-2 text-green-700 font-bold transition-all`}>
                                        Copied!
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 lg:col-span-3">

                        </div>
                    </div>
                </div>
            </PageLayout>
        </>
    )
}
